<template>
  <v-tooltip
    top
    :color="color"
  >
    <template #activator="{ on }">
      <v-icon
        :color="color"
        small
        class="mt-n1"
        v-on="on"
      >
        {{ icon }}
      </v-icon>
    </template>
    {{ (now - then) / then | percentage }}
  </v-tooltip>
</template>
<script>
export default {
  props: {
    then: {
      type: Number,
      default: null,
    },
    now: {
      type: Number,
      default: null,
    },
  },
  computed: {
    color() {
      if (this.now > this.then) {
        return 'green'
      } else if (this.now < this.then) {
        return 'red'
      } else {
        return 'grey'
      }
    },
    icon() {
      if (this.now > this.then) {
        return 'mdi-arrow-up'
      } else if (this.now < this.then) {
        return 'mdi-arrow-down'
      } else {
        return 'mdi-minus'
      }
    },
  },
}
</script>